import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "hero-body has-text-centered" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "section has-text-centered" }
const _hoisted_5 = { class: "buttons is-centered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_carousel_item = _resolveComponent("o-carousel-item")!
  const _component_o_carousel = _resolveComponent("o-carousel")!
  const _component_o_button = _resolveComponent("o-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "title" }, "Simple Image Watermarking Web Services", -1)),
    _createVNode(_component_o_carousel, {
      arrow: _ctx.arrow,
      "arrow-both": _ctx.arrowBoth,
      "arrow-hover": _ctx.arrowHover,
      "icon-pack": _ctx.iconPack,
      "icon-prev": _ctx.iconPrev,
      "icon-next": _ctx.iconNext,
      "icon-size": _ctx.iconSize
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carousels, (carousel, i) => {
          return (_openBlock(), _createBlock(_component_o_carousel_item, { key: i }, {
            default: _withCtx(() => [
              _createElementVNode("section", {
                class: _normalizeClass(`hero is-medium is-${carousel.color}`)
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("h1", _hoisted_3, _toDisplayString(carousel.title), 1)
                ])
              ], 2)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["arrow", "arrow-both", "arrow-hover", "icon-pack", "icon-prev", "icon-next", "icon-size"]),
    _createElementVNode("section", _hoisted_4, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "title" }, "Choose your options", -1)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_o_button, {
          tag: "router-link",
          to: "/embed",
          variant: "danger"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Embed")
          ])),
          _: 1
        }),
        _createVNode(_component_o_button, {
          tag: "router-link",
          to: "/extract",
          variant: "info"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Extract")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}