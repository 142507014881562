import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar is-fixed-top" }
const _hoisted_2 = { class: "navbar-brand" }
const _hoisted_3 = { class: "navbar-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-item",
        to: "/"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" SIWB ")
        ])),
        _: 1
      }),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.makeBurger && _ctx.makeBurger(...args))),
        type: "button",
        class: _normalizeClass(["button navbar-burger", { 'is-active': _ctx.activator }]),
        "aria-label": "menu",
        "aria-expanded": "false",
        "data-target": "navbarBasicExample"
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
        _createElementVNode("span", { "aria-hidden": "true" }, null, -1),
        _createElementVNode("span", { "aria-hidden": "true" }, null, -1)
      ]), 2)
    ]),
    _createElementVNode("div", {
      id: "navbarBasicExample",
      class: _normalizeClass(["navbar-menu", { 'is-active': _ctx.activator }])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "navbar-item",
          to: "/embed"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Embed")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, {
          class: "navbar-item",
          to: "/extract"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Extract")
          ])),
          _: 1
        })
      ])
    ], 2)
  ]))
}